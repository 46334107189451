import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"

import SEO from "components/seo"
import { StateContext } from "components/Context"
import TransitionWrapper from "components/TransitionWrapper"
import Container from "components/Container"
import Banner from "components/Banner"
import { WorksTimeline, FooterTimeline } from "utils/Animations"

const WorksPage = ({ data, transitionStatus }) => {
  const {
    state: { sparks, isLoaded, worksTl },
    dispatch,
  } = useContext(StateContext)

  useEffect(() => {
    if (!sparks || worksTl.totalDuration() > 0) return
    worksTl.add(WorksTimeline(sparks))
    worksTl.add(FooterTimeline())
    dispatch({
      type: "SET_CURRENT_TIMELINE_DURATION",
      value: worksTl.totalDuration(),
    })

    window.worksTl = worksTl
  }, [sparks, dispatch, worksTl])

  const onEntering = () => {
    worksTl.timeScale(1).play()
  }

  const onEntered = () => {
    if (window.locoscroll) {
      window.locoscroll.start()
      window.locoscroll.update()
    }
    if (!isLoaded) return
    worksTl.timeScale(1).play()
  }

  const onExiting = () => {
    worksTl
      .timeScale(1.5)
      .reverse()
      .then(tl => tl.clear(true))
  }

  return (
    <>
      <SEO lang={"en"} pageTitle={data.contentfulPageWorks.title} />

      <TransitionWrapper
        page="works"
        isLoaded={isLoaded}
        transitionStatus={transitionStatus}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
      >
        <div className="pt-40 md:pt-48 h-full">
          <h1 className="fixed font-primary-bold inset-0 flex items-center leading-none justify-center text-center uppercase text-gray-700 opacity-0 text-6xl md:text-10xl pointer-events-none -z-1">
            {data.contentfulPageWorks.title}
          </h1>
          <Banner firstWord="Some" secondWord="Works" />
          <Container className="pointer-events-none">
            {data.contentfulPageWorks.projects.map((project, index) => {
              return (
                <div
                  key={project.id}
                  className="relative pointer-events-auto mt-6"
                >
                  <div className="relative overflow-hidden h-8 md:h-16">
                    <h2
                      className={`absolute`}
                      data-anim-title=""
                      data-anim-line=""
                      data-effect-text={index}
                    >
                      <TransitionLink
                        to={`/works${project.path}`}
                        className={`u-outline ${
                          project.imageDark
                            ? "u-outline--hover-white "
                            : "u-outline--hover-black "
                        }text-3xl md:text-5xl transition duration-500 ease-in-out`}
                        exit={{
                          length: 5,
                        }}
                        entry={{
                          delay: 5,
                        }}
                      >
                        {project.title}
                      </TransitionLink>
                    </h2>
                  </div>
                  <img
                    className="absolute opacity-0 pointer-events-none -z-1"
                    src={project.images[0].fixed.src}
                    alt={project.title}
                    data-effect-image={index}
                  />
                </div>
              )
            })}
          </Container>
        </div>
      </TransitionWrapper>
    </>
  )
}

export default WorksPage

export const query = graphql`
  query WorksPageQuery {
    contentfulPageWorks(node_locale: { eq: "en" }) {
      id
      title
      projects {
        id
        title
        path
        imageDark
        images {
          title
          fixed(
            resizingBehavior: SCALE
            quality: 90
            width: 400
            toFormat: JPG
          ) {
            src
          }
        }
      }
    }
  }
`
